function ChatBoxHeader({
  roomStatus,
  closeChatbox,
  toggleContactInfo,
  hideInfo,
  chatLoading,
  pinChat,
  setUnread,
  archiveChat,
  blockUser,
}) {
  let isSmallScreen = window.matchMedia("(max-width: 768px)").matches;

  return (
    <div className="chat-box-header">
      <div
        className={`header sp-row ${
          isSmallScreen ? "mobile-header-padding" : ""
        }`}
        style={{ borderBottom: "1px solid #E1E7F2", height: "87px" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px",
            width: '100%',
            height: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
              alignItems: "center",
            }}
          >
            <div className="sp-col -wrap btn-back">
              <i
                className="icon-angle-left sp-link"
                style={{ fontSize: 30, marginLeft: -10 }}
                onClick={() => {
                  if (hideInfo) {
                    closeChatbox();
                  } else {
                    toggleContactInfo();
                  }
                }}
              ></i>
            </div>
            <div
              className="sp-logo -lg"
              style={{
                backgroundImage: `url(${
                  roomStatus.img_profile ||
                  "../public/img/default_profile_img.png"
                })`,
              }}
            ></div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                marginLeft: "10px",
                overflow: "hidden",
                height: "70%",
              }}
            >
              <p className="sp-text -ellipsis">
                {roomStatus.firstname
                  ? `${roomStatus.firstname} ${roomStatus.lastname || ""}`
                  : roomStatus.displayname || "-"}
              </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div>
                  {roomStatus.line_user_id && (
                    <div
                      className="logo-header"
                      style={{
                        backgroundImage: `url('../public/img/LINE_SOCIAL_Basic.png')`,
                      }}
                    ></div>
                  )}
                  {roomStatus.facebook_id && (
                    <div
                      className="logo-header"
                      style={{
                        backgroundImage: `url('../public/img/facebook.png')`,
                      }}
                    ></div>
                  )}
                  {roomStatus.instagram_id && (
                    <div
                      className="logo-header"
                      style={{
                        backgroundImage: `url('../public/img/instagram_icon.png')`,
                      }}
                    ></div>
                  )}
                  {!(roomStatus.line_user_id || roomStatus.facebook_id || roomStatus.instagram_id) && (
                    <i className="icon-icon-globe" style={{ color: '#6DA7FD' }}></i>
                  )}
                  </div>
                  <p
                    className="sp-text-help -ellipsis"
                    style={{ fontSize: "12px", marginLeft: (roomStatus.line_user_id || roomStatus.facebook_id ||  roomStatus.instagram_id) ? "4px" : 'unset' }}
                  >
                    {(roomStatus.line_user_id || roomStatus.facebook_id ||  roomStatus.instagram_id) ? roomStatus.social_name : 'Website'}
                  </p>
                </div>
            </div>
          </div>
          {!hideInfo ? (
            <div className="sp-col -wrap btn-contact-info">
              <i
                className={`icon icon-hover ${
                  roomStatus.pinned ? "icon-pin" : "icon-pin-outline"
                }`}
                onClick={() => pinChat()}
                style={
                  chatLoading ? { color: "#AEBAD0", cursor: "not-allowed" } : {}
                }
              ></i>
              <i
                className="icon icon-hover icon-mark-unread"
                onClick={() => setUnread()}
                style={
                  chatLoading ? { color: "#AEBAD0", cursor: "not-allowed" } : {}
                }
              ></i>
              <i
                className="icon icon-hover icon-archive"
                onClick={() => archiveChat()}
                style={
                  chatLoading ? { color: "#AEBAD0", cursor: "not-allowed" } : {}
                }
              ></i>
              <i
                className="icon icon-hover icon-block"
                onClick={() => blockUser()}
                style={
                  chatLoading ? { color: "#AEBAD0", cursor: "not-allowed" } : {}
                }
              ></i>
            </div>
          ) : (
            <div className="sp-col -wrap btn-contact-info">
              {
                roomStatus?.assign_to ? (
                  <ReactTooltip text={roomStatus?.assign_to?.displayname || "-"}>
                    <div
                      className="sp-logo -sm"
                      style={{
                        backgroundImage: `url(${
                          roomStatus?.assign_to?.img_profile ||
                          "../public/img/default_admin.png"
                        })`,
                      }}
                    ></div>
                  </ReactTooltip>
                ) : !_.isEmpty(roomStatus?.assign_team) &&
                  roomStatus?.assign_team?.team_id !== "reception" ? (
                  <ReactTooltip text={roomStatus?.assign_team?.name || "-"}>
                    <div
                      className="sp-logo -sm"
                      style={{
                        backgroundImage: `url(${"../public/img/team.png"})`,
                      }}
                    ></div>
                  </ReactTooltip>
                ) : null
              }
              <i
                style={{ margin: "unset" }}
                className="icon icon-more-vert sp-link"
                onClick={() => toggleContactInfo()}
              ></i>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
